<template>
  <div
    class="base-user-search"
    :class="{ 'base-user-search--open': isDropdownShow }"
    v-on-clickaway="closeList"
  >
    <div class="bus__inner">
      <esmp-input
        class="bus__input"
        :label="placeholder"
        error-message=""
        @focusin="
          inFocus = true;
          isDropdownShow = true;
        "
        @focusout="isFocus = false"
        @input="waitUserPrint"
        v-model="val"
      />
      <div class="bus__icon" v-show="isLoad" />
    </div>

    <div class="select__list" v-if="isDropdownShow">
      <div class="select__list-inner" v-if="isLoad">
        <div class="select__list-item bus__user">
          <span class="bus__user-name">Поиск</span>
        </div>
      </div>
      <div class="select__list-inner" v-else-if="!city">
        <div class="select__list-item bus__user">
          <span class="bus__user-name">
            Для поиска гостиницы выберите место назначения
          </span>
        </div>
      </div>
      <div
        class="select__list-inner"
        v-else-if="filteredHotelList && filteredHotelList.length"
      >
        <div
          class="select__list-item bus__user"
          v-for="hotel in filteredHotelList"
          :key="hotel.hotelCode"
          @click="selected(hotel)"
        >
          <span class="bus__user-name" v-html="hotel.name" />
        </div>
      </div>
      <div class="select__list-inner" v-else="">
        <div class="select__list-item bus__user">
          <span class="bus__user-name">
            По Вашему запросу ничего не найдено
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import { businessTrip } from '@/constants/businessTrip';

const { CancelToken } = axios;
let cancel;

const SEARCH_TIMEOUT_DELAY = 0;

export default {
  name: 'HotelSearch',
  mixins: [clickaway],
  props: {
    placeholder: {
      type: String,
      default: 'Гостиница',
    },
    searchParams: {
      type: Object,
      default: () => ({}),
    },
    searchUrl: {
      type: String,
      default: '/api/business-trip/hotels',
    },
    value: {
      type: String,
      default: undefined,
    },
    city: {
      type: Object,
      default: undefined,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      val: '',
      hotelList: null,
      searchTimeoutId: null,
      isLoad: false,
      inFocus: false,
      isDropdownShow: false,
    };
  },
  computed: {
    filteredHotelList() {
      if (this.hotelList && this.hotelList.length) {
        const filterQuery = this.val.toLowerCase();
        return this.hotelList.filter((hotel) => hotel.name.toLowerCase().includes(filterQuery));
      }

      return null;
    },
    cityName() {
      const getDestination = (block) => {
        if (isEmpty(block) || !block.region) return null;
        if (block.region.abbrev === businessTrip.DESTINATION.cityAbbrev) {
          return block.region;
        }

        if (block.area?.abbrev === businessTrip.DESTINATION.cityAbbrev) {
          return block.area;
        }

        return isEmpty(block.settlement) ? block.city : block.settlement;
      };

      if (!isEmpty(this.city)) {
        const city = getDestination(this.city);
        return city ? city.name : '';
      }

      return '';
    },
  },
  methods: {
    async search() {
      if (cancel) cancel();
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);
      this.isLoad = true;

      // eslint-disable-next-line prefer-object-spread
      const searchParams = Object.assign({}, this.searchParams, {
        city: this.cityName,
      });

      await axios
        .get(this.searchUrl, {
          params: searchParams,
          cancelToken: new CancelToken((c) => {
            cancel = c;
          }),
        })
        .then((response) => {
          this.isLoad = false;
          this.hotelList = response.data;
        })
        .catch((err) => {
          if (axios.isCancel(err)) return;
          // eslint-disable-next-line consistent-return
          return err;
        })
        .finally(() => {
          this.isLoad = false;
        });
    },
    waitUserPrint() {
      if (cancel) cancel();
      this.$emit('input', this.val);
    },
    selected(hotel) {
      this.setHotel(hotel.name, true);
      this.$emit('input', hotel.name);
    },
    setHotel(hotelName, closeDropdown) {
      this.val = hotelName;
      if (closeDropdown) this.isDropdownShow = false;
    },
    closeList() {
      this.isDropdownShow = false;
    },
  },
  created() {
    this.setHotel(this.value);

    if (this.city) this.search();
  },
  watch: {
    value(val) {
      this.setHotel(val);
    },
    city(val) {
      this.hotelList = null;
      if (val) {
        if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);
        this.searchTimeoutId = setTimeout(this.search, SEARCH_TIMEOUT_DELAY);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$bg-gray: #f5f5f5;
$gray: #999;
$gray-light: #ddd;
$gray-dark: #666;
$black: black;
$blue: $color-primary-1-day;
$light-red: red;
$red: red;
$bg-hovered: #888;

.bus__input {
  width: 100%;
  // height: 60px;
  // padding: 0 0 0 20px;
  font-family: 'Basis Grotesque Pro', sans-serif;
  font-size: 18px;
  font-weight: 300;
  color: $black;
  background: #fff;
  // border: 1px solid $gray-light;
  // border-radius: 4px;
  transition: border-color 0.2s ease-in-out;
  &::placeholder {
    color: $blue;
  }
}
.input--invalid {
  border-color: $light-red;
}

.base-user-search {
  position: relative;
  z-index: 4;
}
.base-user-search--open {
  z-index: 5;
}

.select__list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  width: 100%;
  max-height: 180px;
  overflow: hidden;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 6px rgb(0 0 0 / 20%);
  .select--open & {
    max-height: 300px;
  }
}
.select__list-inner {
  width: 100%;
  height: auto;
  max-height: 180px;
  overflow: auto;
  .select--open & {
    max-height: 300px;
  }
}
.select__list-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  line-height: normal;
  padding: 8px 16px;
  color: #515a6e;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  background-color: white;
  &:hover {
    background-color: #f3f3f3;
  }
}

.bus__user {
  padding: 5px 0 5px 20px;
}
.bus__user-name {
  display: inline-block;
  margin-right: 6px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.bus__user-additional-info {
  font-size: 12px;
  line-height: 16px;
  color: $gray;

  span + span {
    &::before {
      content: '|';
    }
  }
}

.bus__user-position {
  display: inline-block;
  margin-right: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $blue;
}
.bus__user-email {
  display: inline-block;
  margin-right: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $gray;
}
.bus__user-fired {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $red;
}
.bus__icon {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 30px;
  height: 30px;
  transform: translateY(-50%);
  img {
    display: inline-block;
    width: 100%;
    height: auto;
  }
}

.bus__label {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;

  .input--hidden & {
    display: none;
  }
}
.bus__label--animated {
  position: absolute;
  top: 22px;
  left: 21px;
  margin-bottom: 0;
  font-size: 16px;
  color: $gray;
  pointer-events: none;
  transition: top 0.2s, font-size 0.2s;
}
.bus--animated-label {
  .bus__input {
    padding-top: 10px;
  }
}
.bus__input:focus ~ .bus__label--animated,
.bus__label--animated.bus__label--filled,
.bus__input:required:not(:invalid) ~ .bus__label--animated {
  top: 4px;
  font-size: 12px;
}

.bus--disabled {
  cursor: not-allowed;
  .bus__input {
    background-color: $gray;
  }
  .bus__label {
    color: white;
  }
}

.bus__input {
  font-size: 16px;
  background-color: $bg-gray;
  &::placeholder {
    font-size: 16px;
    color: $gray;
  }
}
</style>
